// ИМЕЙ ВВИДУ, ЧТО ПЕРЕД ЭТИМ MIDDLEWARE СРАБАТЫВАЕТ ГЛОБАЛЬНЫЙ - INITIALIZE

export default defineNuxtRouteMiddleware((to) => {
  const mainStore = useMainStore()

  if (!to.name) {
    return
  }

  const { year } = to.params
  const isAwards = /awards/.test(to.name as string)

  const currentYearsSet = isAwards ? mainStore.awardYears : mainStore.grandPrixYears

  const isYearAvailable = currentYearsSet.includes(Number(year)) // Проверяем, что год доступен

  if (!isYearAvailable) {
    const params = { year: String(currentYearsSet[0]) }

    return navigateTo({
      name: to.name,
      params,
    })
  }
})
